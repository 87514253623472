import React, { useState } from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { Text, Wrapper } from '../../styled'
import {
  InputStyles,
  LinkWrapper,
  PrivacyWrapper,
  SubmitButtonStyles
} from '../EnterMailbox/styled'
import { schema } from './schema'
import { authenticateUserForDirectAppPswdFlow } from '../../../../../containers/App/AppActions'
import { updateAddMailboxModal } from '../../../../../redux/slice/app.slice'
import { useAuth } from '../../../../../hook/useAuth'
import ImapErrorModal from '../../../ImapErrorModal'

export const EnterAppPassword = ({ email, nextStep, provider }) => {
  const { loadNewUsers } = useAuth()
  const dispatch = useDispatch()
  const [showImapErrorModal, setShowImapErrorModal] = useState(false)

  const handleSubmit = async (data, { setErrors, setSubmitting }) => {
    const authParams = {
      provider: provider,
      email: email,
      secret: data.appPassword,
      checkbox: false
    }
    try {
      await authenticateUserForDirectAppPswdFlow(authParams)
      loadNewUsers()
      dispatch(updateAddMailboxModal({ show: false, type: undefined }))
      nextStep()
    } catch (e) {
      setSubmitting(false)
      let errorMessage

      if (e.response.data.title === 'ValidationError') {
        errorMessage = (
          <>
            The app password you have entered is incorrect.
            <br />
            Please verify the password or create a new one.
          </>
        )
      } else if (e.response.data.title === 'ImapDisabledError') {
        setShowImapErrorModal(true)
      } else {
        errorMessage = 'Something goes wrong. Try again'
      }
      setErrors({ appPassword: errorMessage })
    }
  }

  const initialValues = {
    appPassword: null
  }
  return (
    <Wrapper>
      <Text style={{ width: '445px', maxWidth: '445px' }}>
        Enter 16-digit App Password for
        <br />
        <b>{email}</b>
      </Text>

      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        initialValues={initialValues}>
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Col} controlId="appPasswordlValidation">
              <Form.Control
                style={InputStyles}
                disabled={isSubmitting}
                className="text-center"
                type="text"
                // name="appPassword"
                value={values.appPassword}
                onChange={event => {
                  setFieldValue(
                    'appPassword',
                    event.target.value?.replace(/[\s-]/g, '')
                  )
                }}
                isInvalid={!!errors.appPassword}
                placeholder="Enter App Password"
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  marginTop: 10,
                  lineHeight: 1
                }}>
                {errors.appPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              style={SubmitButtonStyles}
              type="submit"
              disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ width: 18, height: 18, marginRight: 8 }}
                  />
                  Connecting...
                </>
              ) : (
                <>Connect My Account</>
              )}
            </Button>
          </Form>
        )}
      </Formik>
      <PrivacyWrapper style={{ paddingBottom: '20px' }}>
        By connecting your mailbox with Unroll.Me, you understand that you may
        be enrolled in our{' '}
        <LinkWrapper target="_blank" href="/your-data">
          measurement panel
        </LinkWrapper>
        , and you are agreeing to our{' '}
        <LinkWrapper target="_blank" href="/legal/terms">
          Terms of Service
        </LinkWrapper>{' '}
        and{' '}
        <LinkWrapper target="_blank" href="/legal/privacy">
          Privacy Policy
        </LinkWrapper>
        .
      </PrivacyWrapper>
      {showImapErrorModal && (
        <ImapErrorModal
          show={showImapErrorModal}
          setShow={setShowImapErrorModal}
        />
      )}
    </Wrapper>
  )
}

import React from 'react'
import { Button, ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { BoldText, KeepLabel } from './styled'

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .progress {
    width: 620px;
    height: 34px;
    border-radius: 17px;
    border: solid 1px rgba(60, 194, 200, 0.75);
    background-color: #fff;
  }

  .progress-bar {
    background-color: rgba(60, 194, 200, 0.75) !important;
    border-radius: 17px;
  }

  .progress-content {
    position: relative;
    margin-top: 44px;
  }

  .progress-content-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: #00bcbb;
  }

  img {
    width: 450px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`
const Tutorial2 = ({ nextStep }) => {
  return (
    <>
      <Styled>
        <h3>
          Edit <KeepLabel>Subscriptions</KeepLabel>
        </h3>
        <p>Unsubscribed from something but want to keep it instead?</p>
        <p>
          Tap the <BoldText>subscriptions</BoldText> tab and make edits to your
          subscriptions with ease.{' '}
        </p>
        <p>Nothing is permanent…make changes as you wish.</p>
        {/*<img  src={`${process.env.PUBLIC_URL}/images/tutorial/tutorial41.png`} alt="tutorial"/>*/}
      </Styled>
      <Button
        onClick={() => {
          nextStep()
        }}>
        Cool!
      </Button>
    </>
  )
}

export default Tutorial2

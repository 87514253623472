import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { useUpdateMutation } from '../../../redux/Api/SubscriptionsApi'

const Styled = styled.div`
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 17px;
    font-size: 16px;
    border: none;
    font-weight: bold;
    color: rgb(0, 0, 0, 0.25);
  }

  > div {
    display: flex;
    gap: 5px;
  }

  .keep-button {
    width: 80px;
  }

  .unsubscribe-button {
    width: 130px;
  }

  .rollup-button {
    width: 90px;
  }

  .keep-button:hover {
    color: white;
    background-color: #3cc2c8 !important;
  }

  .unsubscribe-button:hover {
    color: white;
    background-color: #c83c3c !important;
  }

  .rollup-button:hover {
    color: white;
    background-color: #f7931d !important;
  }

  .keep .keep-button {
    background-color: #3cc2c8 !important;
    color: white !important;
  }

  .unsubscribed .unsubscribe-button {
    background-color: #c83c3c !important;
    color: white !important;
  }

  .rollup .rollup-button {
    background-color: #f7931d !important;
    color: white !important;
  }
`

const SubscriptionButtonsGroup = ({
  subscription,
  type,
  subtype,
  setSelectedSubscription,
  selectedSubscription
  // searchStr
}) => {
  const [update] = useUpdateMutation()
  const isGroup = Array.isArray(subscription)

  const handleStateClick = async (state, subtypeBtn) => {
    if (subtype === subtypeBtn) {
      return
    } else {
      if (isGroup) {
        subscription.forEach(entity => {
          update({
            subscription: entity,
            state,
            isRolledup: false
          })
        })
      } else {
        update({
          subscription,
          state,
          isRolledup: type === 'rolledup'
        })
      }
      if (setSelectedSubscription) {
        if (selectedSubscription && selectedSubscription.id === subscription.id)
          setSelectedSubscription(null)
      }
    }
  }

  return (
    <Styled>
      <div className={subtype}>
        <Button
          className="keep-button"
          variant="outline-secondary"
          onClick={() => {
            handleStateClick('inbox', 'keep')
          }}>
          Keep {isGroup && 'All'}
        </Button>
        <Button
          className="unsubscribe-button"
          variant="outline-secondary"
          onClick={() => {
            handleStateClick('unsubscribed', 'unsubscribed')
          }}>
          Unsubscribe {isGroup && 'All'}
        </Button>
        <Button
          className="rollup-button"
          variant="outline-secondary"
          onClick={() => {
            handleStateClick('rollup', 'rollup')
          }}>
          Rollup {isGroup && 'All'}
        </Button>
      </div>
    </Styled>
  )
}

export default SubscriptionButtonsGroup

import React from 'react'
import { Button, ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { updateUserOnboarding } from '../../../redux/slice/app.slice'

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .progress {
    width: 620px;
    height: 34px;
    border-radius: 17px;
    border: solid 1px rgba(60, 194, 200, 0.75);
    background-color: #fff;
  }

  .progress-bar {
    background-color: rgba(60, 194, 200, 0.75) !important;
    border-radius: 17px;
  }

  .progress-content {
    position: relative;
    margin-top: 44px;
  }

  .progress-content-text {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: #00bcbb;
    margin-bottom: 10px;
  }
`
const Tutorial1 = ({ nextStep, progress }) => {
  const dispatch = useDispatch()
  return (
    <>
      <Styled>
        <h3>Almost Done</h3>
        <p>Hang tight while we finish searching for subscriptions</p>
        <p>in your inbox. We’re almost done.</p>
        <div className="progress-content">
          <div className="progress-content-text">finding subscriptions...</div>
          <ProgressBar now={progress} />
        </div>
      </Styled>
      <Button
        onClick={() => {
          dispatch(updateUserOnboarding(false))
        }}>
        Let's Unroll!
      </Button>
    </>
  )
}

export default Tutorial1

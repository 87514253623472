import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { defineMessages } from 'react-intl.macro'
import styled from 'styled-components'

import Page from '../../components/Page'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Wrapper from '../../components/Wrapper'
import { track as trackEvent } from '../../global/eventTracking'
import AppButtons from '../../components/AppButtons'
import SignupForm from './SignupForm'
import { formatMessages, formatMessage } from '../../i18n/utils'
import MailProviderErrorModal from '../../components/MailProviderErrorModal'
import { providerData } from '../../global/config'
import eventNames from '../../global/eventConstants'
import { getCookieForUserAuthentication } from '../../global/cookies'

const FooterBox = styled(Box)`
  width: 400px;
  margin-left: -50px;
  a {
    text-decoration: none;
    color: #41c1c8;
  }

  a:hover {
    color: #41c1c8;
  }
`

const StyledFooter = styled.div`
  a {
    text-decoration: none;
    color: #41c1c8;
  }

  a:hover {
    color: #41c1c8;
  }
`

const messageDescriptors = defineMessages({
  description: {
    id: 'containers.Signup.description',
    defaultMessage: '{title} to easily unsubscribe from newsletters.',
    skipGroupFormatting: true
  },
  accountAlready: {
    id: 'containers.Signup.accountAlready',
    defaultMessage: 'Already have an account?'
  },
  login: {
    id: 'containers.Signup.login',
    defaultMessage: 'Log In'
  },
  newUser: {
    id: 'containers.Signup.newUser',
    defaultMessage: 'New to Unroll.Me?'
  },
  signup: {
    id: 'containers.Signup.signup',
    defaultMessage: 'Sign Up'
  },
  or: {
    id: 'containers.Signup.or',
    defaultMessage: 'or'
  },
  footerL1: {
    id: 'containers.CustomAuth.footerLine1',
    defaultMessage: 'By connecting your mailbox with Unroll.Me, you understand'
  },
  footerL2P1: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'that you may be enrolled in our'
  },
  footerL2P2: {
    id: 'containers.CustomAuth.footerL1P2',
    defaultMessage: 'measurement panel'
  },
  footerL2P3: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: ', and'
  },
  footerL3P1: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'you are agreeing to our'
  },
  footerL3P2: {
    id: 'containers.CustomAuth.footerL1P2',
    defaultMessage: 'Terms of Service'
  },
  footerL3P3: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'and'
  },
  footerL3P4: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'Privacy Policy'
  }
})

const Signup = ({ title, subTitle, match, whiteSpace }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [redirectProvider, setRedirectProvider] = useState()
  const [selectedProvider, setSelectedProvider] = useState()
  const messages = {
    ...formatMessages(messageDescriptors),
    description: formatMessage(messageDescriptors.description, { title })
  }
  const path = location.pathname
  let page
  if (path === '/login') page = 'login'
  if (path === '/adduser') page = 'addUser'
  if (path === '/signup') page = 'signup'
  if (path === '/reauthenticate') page = 'reauthenticate'
  if (path === '/reauthenticate/finalize') page = 'reauthenticateFinalize'

  useEffect(() => {
    if (getCookieForUserAuthentication() && page !== 'addUser') {
      navigate('/app/inbox')
    }
    const params = new Proxy(new URLSearchParams(location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    setRedirectProvider(providerData[params.redirectProvider])
  }, [])

  const track = (eventName, eventProps) => {
    trackEvent(eventName, {
      ...eventProps,
      Page: eventNames[page].pageName
    })
  }

  const closeModal = () => {
    track(eventNames.toErrorProviderModal.clickedCancel)
    setRedirectProvider(null)
  }

  const onAccept = () => {
    track(eventNames.toErrorProviderModal.clickedAccept)
    setSelectedProvider(redirectProvider.type.toLowerCase())
    setRedirectProvider(null)
  }

  const getMailProviderName = () => {
    if (redirectProvider && redirectProvider.type) {
      if (redirectProvider.type.toUpperCase() === 'Google') {
        return 'Gmail'
      }
      const mailProviderName = redirectProvider.type.toLowerCase()
      return (
        mailProviderName.charAt(0).toUpperCase() + mailProviderName.slice(1)
      )
    }
    return null
  }

  return (
    <Page
      title={title}
      showNav={false}
      showFooter={false}
      bodyClassName="white"
      description={messages.description}>
      <Box display="flex" flexDirection="column" height="100vh" py={6}>
        <Wrapper maxWidth="340px" textAlign="center">
          <Box mb={5}>
            <Text
              as="h1"
              color="gray.3"
              fontWeight={0}
              fontSize={7}
              m={0}
              mb={4}>
              {title}
            </Text>
            {/*<Text*/}
            {/*  color="gray.3"*/}
            {/*  fontSize={4}*/}
            {/*  fontWeight={2}*/}
            {/*  whiteSpace={whiteSpace}>*/}
            {/*  {subTitle}*/}
            {/*</Text>*/}

            <Box pt={3}>
              <AppButtons track={track} />

              <Text my={3} as="div" fontSize={4}>
                {messages.or}
              </Text>

              <SignupForm track={track} redirectProvider={selectedProvider} />
            </Box>
          </Box>

          <FooterBox
            textAlign="center"
            fontSize={['11px', '11px', '13px', '13px']}
            mt={5}>
            <Text>{messages.footerL1}</Text>
            <Text>
              {messages.footerL2P1}{' '}
              <a href="/your-data">{messages.footerL2P2}</a>
              {messages.footerL2P3}
            </Text>
            <Text mb={6}>
              {messages.footerL3P1}{' '}
              <a href="/legal/terms">{messages.footerL3P2}</a>{' '}
              {messages.footerL3P3}{' '}
              <a href="/legal/privacy">{messages.footerL3P4}</a>.
            </Text>
          </FooterBox>

          {location.pathname.includes('signup') ? (
            <StyledFooter>
              <Text color="gray.3" fontSize={2}>
                {messages.accountAlready}{' '}
                <Link to="/login">{messages.login}</Link>
              </Text>
            </StyledFooter>
          ) : (
            <StyledFooter>
              <Text color="gray.3" whiteSpace="pre" fontSize={2}>
                {messages.newUser} {'\n'}{' '}
                <Link to="/signup">{messages.signup}</Link>
              </Text>
            </StyledFooter>
          )}
        </Wrapper>
        {redirectProvider && (
          <MailProviderErrorModal
            track={track}
            mailProvider={getMailProviderName()}
            closeModal={closeModal}
            onAccept={onAccept}
          />
        )}
      </Box>
    </Page>
  )
}

export default Signup

import React from 'react'
import { Formik } from 'formik'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { get } from 'lodash'
import {
  EnterMailboxWrapper,
  InputStyles,
  LinkWrapper,
  PrivacyWrapper,
  SubmitButtonStyles,
  Text
} from './styled'
import { schema } from './schema'
import {
  getAuthenticateActionForCustomAuth,
  submitEmailForLogin
} from '../../../../../containers/App/AppActions'

export const EnterLoginPage = ({
  setEmail,
  nextStep,
  provider,
  switchToLoginFlow,
  initialEmail
}) => {
  const handleSubmit = async (data, actions) => {
    setEmail(data.email)
    switchToLoginFlow(false)
    const customAction = await getAuthenticateActionForCustomAuth(
      data.email.trim().toLowerCase(),
      provider,
      false
    )

    if (customAction === 'signup' || customAction === 'authorize') {
      setEmail(data.email.trim().toLowerCase())

      nextStep()
      return
    }
    switchToLoginFlow(true)

    try {
      await submitEmailForLogin(data.email.trim().toLowerCase())
      actions.setErrors({ email: '' })
      nextStep()
    } catch (err) {
      actions.setSubmitting(false)
      let errMsg = 'Something went wrong. Try again.'
      if (err.response && err.response.status === 400) {
        errMsg = get(err, 'response.data.detail', errMsg)
      }
      actions.setErrors({ email: errMsg })
    }
  }

  const initialValues = {
    email: initialEmail || ''
  }

  return (
    <EnterMailboxWrapper>
      <Text>Enter your email address below</Text>

      <Formik
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={initialValues}>
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Col} controlId="emailValidation">
              <Form.Control
                style={InputStyles}
                disabled={isSubmitting}
                className="text-center"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
                placeholder="Email Address"
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  marginTop: 10
                }}>
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              style={SubmitButtonStyles}
              type="submit"
              disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ width: 18, height: 18, marginRight: 8 }}
                  />
                  Checking...
                </>
              ) : (
                <>Continue</>
              )}
            </Button>
          </Form>
        )}
      </Formik>

      <PrivacyWrapper>
        By connecting your mailbox with Unroll.Me, you understand that you may
        be enrolled in our{' '}
        <LinkWrapper target="_blank" href="/your-data">
          measurement panel
        </LinkWrapper>
        , and you are agreeing to our{' '}
        <LinkWrapper target="_blank" href="/legal/terms">
          Terms of Service
        </LinkWrapper>{' '}
        and{' '}
        <LinkWrapper target="_blank" href="/legal/privacy">
          Privacy Policy
        </LinkWrapper>
        .
      </PrivacyWrapper>
    </EnterMailboxWrapper>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Button, Carousel, Modal, ProgressBar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Tutorial1 from './Tutorial1'
import Tutorial2 from './Tutorial2'
import Tutorial21 from './Tutorial21'
import Tutorial31 from './Tutorial31'
import Tutorial41 from './Tutorial41'
import Tutorial5 from './Tutorial5'
import Final from './Final'
import { updateUserOnboarding } from '../../../redux/slice/app.slice'

const CarouselStyled = styled.div`
  width: 100%;
  height: 100%;

  .carousel {
    width: 100%;
    height: 100%;
  }

  .carousel-inner {
    width: 100%;
    height: 100%;
  }

  .carousel-item {
    width: 100%;
    height: 100%;
  }

  .carousel-caption {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carousel-caption h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .carousel-caption p {
    font-size: 16px;
    opacity: 0.75;
    line-height: 1.25;
    text-align: center;
  }

  .carousel-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .carousel-caption .btn {
    width: 343px;
    height: 50px;
    background-color: #00bcbb;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .carousel-caption .btn:active {
    background-color: #009e9dff;
  }
`

const TUTORIAL_STEPS = [<Tutorial1 />, <Tutorial2 />]

const WelcomeInstructions = () => {
  const [show, setShow] = useState(true)
  const ref = useRef(null)
  const [index, setIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (index === 5 && progress >= 100) {
      dispatch(updateUserOnboarding(false))
    }
  }, [index, progress])

  useEffect(() => {
    dispatch(updateUserOnboarding(true))
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval)
          return 100
        } else {
          const newValue = prevProgress + 3.3
          return newValue
        }
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }
  const onPrevClick = () => {
    ref.current.prev()
  }
  const onNextClick = () => {
    ref.current.next()
  }

  return (
    <Modal
      show={show}
      slide={false}
      fullscreen={true}
      backdrop="static"
      keyboard={false}
      onHide={() => setShow(false)}>
      {/*<Modal.Header closeButton>*/}
      {/*  /!*<Modal.Title>Modal</Modal.Title>*!/*/}
      {/*</Modal.Header>*/}
      <Modal.Body>
        <CarouselStyled>
          <Carousel
            ref={ref}
            controls={false}
            indicators={false}
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
            variant="dark">
            <Carousel.Item>
              <Carousel.Caption>
                <Tutorial1 nextStep={onNextClick} progress={progress} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <Tutorial21 nextStep={onNextClick} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <Tutorial31 nextStep={onNextClick} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <Tutorial41 nextStep={onNextClick} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <Tutorial5 nextStep={onNextClick} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <Final nextStep={() => setShow(false)} progress={progress} />
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </CarouselStyled>
      </Modal.Body>
    </Modal>
  )
}

export default WelcomeInstructions

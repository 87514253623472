import { createApi } from '@reduxjs/toolkit/query/react'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import UnrollApi from '../../http/UnrollApi'
import store from '../store'
import { addNotification, removeNotification } from '../slice/app.slice'
import { CONTACT_SUPPORT_TEAM } from '../../components/new/constants'

const stateToTabMap = new Map([
  ['inbox', 'Kept'],
  ['unsubscribed', 'Unsubscribed'],
  ['rollup', 'Rolled Up']
])

const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => async ({
  url,
  method,
  data,
  params,
  configs
}) => {
  try {
    const callUrl = url.startsWith('http') ? url : baseUrl + url
    const result = await UnrollApi({
      url: callUrl,
      method,
      data,
      params,
      ...configs
    })
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

const getQueryUrl = ({
  order = 'domain',
  page = 1,
  limit = 200,
  direction = 'asc',
  offset = 0,
  state
  // searchStr="",
}) => {
  if (state === 'rolledup') {
    const date = moment().format('YYYY-MM-DD')
    return `rollups?date=${date}&embed=subscriptions&days=30&order=created_at&direction=desc&page=${page}&limit=${limit}`
  }
  return `subscriptions?order=${order}&page=${page}&direction=${direction}&state=${state}`
}

export const SubscriptionsApi = createApi({
  reducerPath: 'SubscriptionsApi',
  tagTypes: ['Subscriptions'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/'
  }),
  endpoints: build => ({
    getSubscriptionsByState: build.query({
      query: options => ({
        url: getQueryUrl(options),
        method: 'get'
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Subscriptions', id })),
              { type: 'Subscriptions', id: 'LIST' }
            ]
          : [{ type: 'Subscriptions', id: 'LIST' }]
    }),
    getSubscriptionMail: build.query({
      query: url => ({
        url,
        method: 'get',
        configs: {
          headers: {
            Accept: 'text/html',
            'Content-Type': 'text/html'
          }
        }
      })
    }),
    updateRollup: build.mutation({
      query: option => ({
        url: `subscriptions/${option.id}`,
        method: 'PATCH',
        data: { state: option.state }
      }),
      invalidatesTags: [{ type: 'Subscriptions', id: 'LIST' }]
    }),
    loadMoreSubscriptions: build.mutation({
      query: options => ({
        url: getQueryUrl(options),
        method: 'get'
      }),
      async onQueryStarted({ state, page }, { dispatch, queryFulfilled }) {
        let patchResult
        try {
          const { data } = await queryFulfilled
          patchResult = dispatch(
            SubscriptionsApi.util.updateQueryData(
              'getSubscriptionsByState',
              { state: state },
              draft => {
                draft.data = [...draft.data, ...data.data]
                draft.pagination = data.pagination
              }
            )
          )
        } catch (e) {
          if (patchResult) {
            patchResult.undo()
          }
          console.error(e)
        }
      }
    }),
    update: build.mutation({
      query: ({ subscription, state, isRolledup }) => ({
        url: `subscriptions/${subscription.id}`,
        method: 'PATCH',
        data: { state: state }
      }),
      async onQueryStarted(
        { subscription, state, isRolledup },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          SubscriptionsApi.util.updateQueryData(
            'getSubscriptionsByState',
            {
              state: subscription.state
            },
            draft => {
              draft.pagination.total_count = draft.pagination.total_count
                ? draft.pagination.total_count - 1
                : 0
              draft.data = draft.data.filter(s => s.id !== subscription.id)
            }
          )
        )

        const patchResult2 = dispatch(
          SubscriptionsApi.util.updateQueryData(
            'getSubscriptionsByState',
            {
              state: state
            },
            draft => {
              const newState = Object.assign({}, subscription, { state: state })
              draft.data.push(newState)
              draft.pagination.total_count = draft.pagination.total_count + 1
            }
          )
        )
        // let updateRolledUp
        // if (isRolledup) {
        const updateRolledUp = dispatch(
          SubscriptionsApi.util.updateQueryData(
            'getSubscriptionsByState',
            {
              state: 'rolledup'
            },
            draft => {
              draft.data = draft.data.map(rollup => {
                if (rollup.subscription.id === subscription.id) {
                  rollup.subscription.state = state
                }
                return rollup
              })
            }
          )
        )
        // }

        try {
          await queryFulfilled
          const id = uuid()
          store.dispatch(
            addNotification({
              id: id,
              message: (
                <>
                  The status of <b>{subscription?.name}</b> has been
                  successfully changed to <b>{stateToTabMap.get(state)}</b>
                </>
              ),
              type: 'success'
            })
          )
          setTimeout(() => {
            store.dispatch(removeNotification(id))
          }, 3000)
        } catch (err) {
          const id = uuid()
          store.dispatch(
            addNotification({
              id: id,
              message: (
                <>
                  There seems to be an error when attempting to change the{' '}
                  <b>{subscription?.name}</b> status to{' '}
                  <b>{stateToTabMap.get(state)}</b>. Please contact{' '}
                  <CONTACT_SUPPORT_TEAM />
                </>
              ),
              type: 'danger'
            })
          )
          setTimeout(() => {
            store.dispatch(removeNotification(id))
          }, 7000)
          patchResult.undo()
          patchResult2.undo()
          if (updateRolledUp) {
            updateRolledUp.undo()
          }
          throw err
        }
      }
    })
  })
})

export const {
  useGetSubscriptionsByStateQuery,
  useGetSubscriptionMailQuery,
  useUpdateMutation,
  useLoadMoreSubscriptionsMutation,
  useUpdateRollupMutation,
  usePrefetch
} = SubscriptionsApi

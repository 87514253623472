import React from 'react'
import styled from 'styled-components'
import { CloseButton, OverlayTrigger, Spinner } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { useGetSubscriptionMailQuery } from '../../../redux/Api/SubscriptionsApi'
import SubscriptionButtonsGroup from '../SubscriptionButtonsGroup'
import { useSubscriptions } from '../context/SubscriptionsProvider'
import { formatIframeString } from '../../../global/IframeUtilities'

const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    height: 100%;
  }

  .spinner {
    padding-top: 20px;
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
    color: #3cc2c8;
    --bs-spinner-border-width: 4px;
  }

  .header-item {
    color: black;
    font-weight: bold;
    align-items: center;
    padding: 15px;
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    font-size: 19.8px;
    //border-bottom: 2px solid rgb(40, 40, 40, 0.25);
  }

  .buttons {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .buttons div div {
    text-align: center;
  }

  .full-screen-button:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .btn-close {
    margin: 0 !important;
    font-size: 10px !important;
    padding: 10px !important;
    border: 1px solid rgb(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
  }
`

const getSubtypeByState = state => {
  if (state === 'inbox') {
    return 'keep'
  }
  if (state === 'unsubscribed') {
    return 'unsubscribed'
  }
  if (state === 'rollup') {
    return 'rollup'
  }
}

const EmailPreviewContainer = (
  {
    // subscription,
    // setSelectedSubscription,
    // type
  }
) => {
  const {
    selectedSubscription,
    setSelectedSubscription,
    type,
    setShowFullPreview
  } = useSubscriptions()
  const { data, isLoading, isFetching, isError } = useGetSubscriptionMailQuery(
    selectedSubscription.links.self
  )

  return (
    <Styled type={type}>
      <div style={{ borderBottom: '2px solid rgb(40, 40, 40, 0.25)' }}>
        <div className="header-item">
          <div
            style={{
              width: '100%',
              paddingRight: '10px'
            }}>
            {(selectedSubscription && selectedSubscription.name) ||
              selectedSubscription.subject}
          </div>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Open in Full Screen</Tooltip>}>
            <AiOutlineFullscreen
              onClick={() => setShowFullPreview(true)}
              className="full-screen-button"
              style={{
                marginRight: 15,
                fontSize: 30,
                fill: '#4ab9ba',
                stroke: '#4ab9ba',
                strokeWidth: 50
              }}
            />
          </OverlayTrigger>
          <CloseButton onClick={() => setSelectedSubscription(undefined)} />
        </div>
        {type === 'rolledup' && (
          <div className="buttons">
            <SubscriptionButtonsGroup
              subscription={selectedSubscription.subscription}
              subtype={getSubtypeByState(
                selectedSubscription?.subscription?.state
              )}
              type={type}
              // setSelectedSubscription={setSelectedSubscription}
            />
          </div>
        )}
      </div>

      {isLoading || isFetching ? (
        <Spinner
          className="spinner"
          animation="border"
          style={{ marginTop: '80px' }}
        />
      ) : isError ? (
        <div style={{ textAlign: 'center', margin: 'auto' }}>
          <h2 style={{ paddingBottom: '5px' }}>Couldn't load your email</h2>
          <h4>It seems something goes wrong when loading your email</h4>
        </div>
      ) : (
        <iframe title="" srcDoc={formatIframeString(data)} allowFullScreen />
      )}
    </Styled>
  )
}

export default EmailPreviewContainer

import cookies from 'browser-cookies'
import { defineMessages } from 'react-intl.macro'
import { get } from 'lodash'
import { parse, stringify } from 'query-string'
import {
  eraseCookie,
  eraseCookieForUserAuthentication,
  getCookie,
  getCookieForUserAuthentication,
  getDecodedAccounts,
  setCookie,
  setCookieForUserAuthentication,
  setEncodedAccounts
} from '../../global/cookies'
import { addNotification } from '../../components/Notification/NotificationActions'
import { axiosMethods as axios } from '../../global/axiosHelpers'
import { track } from '../../global/eventTracking'
import { formatMessage, formatMessages } from '../../i18n/utils'
import loginMiddleware from '../../global/loginMiddleware'
import { providerData } from '../../global/config'
import store from '../../redux/store'
import {
  getUser as getUserRedux,
  updateUserOnboarding,
  updateUserPolling,
  updateUsers
} from '../../redux/slice/app.slice'

const messageDescriptors = defineMessages({
  authErrorNotif: {
    id: 'containers.App.Actions.authErrorNotif',
    defaultMessage:
      'We were unable to login with the password provided. Ensure it is the 16-digit application password generated by {provider} and try again.',
    skipGroupFormatting: true
  },
  loggedOutNotif: {
    id: 'containers.App.Actions.loggedOutNotif',
    defaultMessage: 'You have successfully logged out of'
  },
  loggedInNotif: {
    id: 'containers.App.Actions.loggedInNotif',
    defaultMessage: 'You are now logged into'
  },
  disabledAccountNotif: {
    id: 'containers.App.Actions.disabledAccountNotif',
    defaultMessage: 'You have successfully disabled your account for'
  },
  gdprDetail: {
    id: 'containers.App.Actions.gdprDetail',
    defaultMessage: 'I do not live in the European Union'
  }
})
const imapErrorMsg =
  'Your account is not enabled for IMAP use. Please visit your Gmail settings page and enable your account for IMAP access.'
const imapErrorDetail =
  'Your account is not enabled for IMAP use. Please visit your Gmail settings page and enable your account for IMAP access. (Failure)'
const messages = formatMessages(messageDescriptors)

export const GET_USER = 'GET_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const LOGOUT_USER = 'LOGOUT_USER'

function getUserAsync(user) {
  store.dispatch(getUserRedux({ user }))
}

function updateUsersState(users) {
  store.dispatch(updateUsers(users.map(user => user[2])))
}

function getGdprParams() {
  let gdprParams
  let isoTimestamp
  const isEuResidentAttestation = cookies.get('gdpr-is-eu-resident-attestation')
  const notEuResidentAttestation = cookies.get(
    'gdpr-not-eu-resident-attestation'
  )
  const euResidentAttestationTimestamp = cookies.get(
    'gdpr-eu-attestation-timestamp'
  )

  if (!!notEuResidentAttestation && !!euResidentAttestationTimestamp) {
    isoTimestamp = new Date(
      parseInt(euResidentAttestationTimestamp)
    ).toISOString()
    gdprParams = {
      gdpr_is_eu_resident: false,
      gdpr_timestamp: isoTimestamp,
      gdpr_detail: notEuResidentAttestation
    }
  } else if (isEuResidentAttestation) {
    isoTimestamp = new Date(
      parseInt(euResidentAttestationTimestamp)
    ).toISOString()
    gdprParams = {
      gdpr_is_eu_resident: true,
      gdpr_timestamp: isoTimestamp,
      gdpr_detail: notEuResidentAttestation
    }
  } else {
    isoTimestamp = new Date().toISOString()
    gdprParams = {
      gdpr_is_eu_resident: false,
      gdpr_timestamp: isoTimestamp,
      gdpr_detail: messages.gdprDetail
    }
  }

  return gdprParams
}

export const logout = () => {
  eraseCookie('accounts')
  eraseCookie('accounts0')
  setCookie('accCount', window.btoa('0'))
  eraseCookieForUserAuthentication()
}

export const logoutUser = deletingAccount => {
  const decodedAccounts = getDecodedAccounts()
  if (Object.values(decodedAccounts).length > 1) {
    return (dispatch, getState) => {
      const currentUserState = getState()
      const currentEmail = currentUserState.app.user.email
      const message = !deletingAccount
        ? `${messages.loggedOutNotif} ${currentEmail}`
        : `${messages.disabledAccountNotif} ${currentEmail}.`
      delete decodedAccounts[currentUserState.app.user.id]
      setCookie('accountSwitichingNotification', message)
      setEncodedAccounts(decodedAccounts)
      setCookieForUserAuthentication(Object.values(decodedAccounts)[0])
      // dispatch(push('/app/inbox'))
      window.location.reload()
    }
  }
  eraseCookieForUserAuthentication()
  eraseCookie('accounts')
  return dispatch => {
    dispatch({
      type: LOGOUT_USER
    })
  }
}

const numberOfRetries = 3
const numberOfInProgressRetries = 20
let retryIndex = 0
let retryInProgressIndex = 0

export async function startUserPolling() {
  const getUserStatus = async () => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API}/users/me`)
      if (resp.data.scan_status === 'failed') {
        await axios.post(`${process.env.REACT_APP_API}/users/me/scans`)
        if (retryIndex < numberOfRetries) {
          retryIndex += 1
          await new Promise(resolve => setTimeout(resolve, 3000))
          await getUserStatus()
        } else {
          // dispatch(addNotification({ context: 'negative' }))
        }
        return
      }

      if (
        resp.data.scan_status === 'in_progress' ||
        store.getState().app.isUserOnboarding
      ) {
        store.dispatch(updateUserPolling(true))

        // if in progress then poll again in 1 second for 60 times
        store.dispatch(updateUserPolling(false))
      }

      if (
        resp.data.scan_status === 'succeeded' &&
        !store.getState().app.isUserOnboarding
      ) {
        store.dispatch(updateUserPolling(false))
      }
      await getUserAsync(resp.data)
    } catch (err) {
      if (err.code === 'REAUTH') {
        const accounts = getAllAccounts()
        const reauthAccount = accounts.find(
          account => account[1] === getCookieForUserAuthentication()
        )
        if (reauthAccount) {
          getUserAsync({
            id: reauthAccount[0],
            email: reauthAccount[2],
            reauth: true
          })
        }
      } else {
        console.error('error: ', err)
        throw error
      }
    }
  }
  // begin polling
  await getUserStatus()
}

export const getAllAccounts = () => {
  let accounts = []
  const count = +window.atob(getCookie('accCount')) || 0

  if (count === 0) {
    return accounts
  }

  const parts = Math.trunc(count / 4)

  for (let i = 0; i <= parts; i++) {
    try {
      const acc = JSON.parse(atob(getCookie(`accounts${i}`)))
      accounts.push(...acc)
    } catch (e) {
      console.error(e)
    }
  }
  return accounts
}

export const saveAllAccounts = (accounts = []) => {
  eraseCookie('accCount')
  setCookie('accCount', window.btoa(accounts.length.toString()))

  const parts = Math.trunc(accounts.length / 4)
  for (let i = 0; i <= parts; i++) {
    const start = 4 * i
    const end = 4 * (i + 1)
    const acc = accounts.slice(start, end)
    eraseCookie(`accounts${i}`)
    setCookie(`accounts${i}`, window.btoa(JSON.stringify(acc)))
  }
}

export async function getUser({
  token,
  redirectToAfterLogin,
  trackEvent,
  hasPassword,
  reload = false,
  isReauth,
  history
}) {
  // set cookie
  if (token) {
    setCookieForUserAuthentication(token)
  } else {
    token = getCookieForUserAuthentication()
  }
  if (!token) {
    logoutUser()
  }

  async function fetchUser() {
    try {
      eraseCookie('activeAccount')
      eraseCookie('activeAccounts')
      eraseCookie('accounts')
      eraseCookie('newAccount')

      const resp = await axios.get(`${process.env.REACT_APP_API}/users/me`)

      const accounts = getAllAccounts()

      const foundedAccountIndex = accounts.findIndex(
        account => account[0] === resp.data.id
      )
      if (foundedAccountIndex === -1) {
        accounts.push([resp.data.id, token, resp.data.email])
      } else {
        accounts[foundedAccountIndex][1] = token
        setCookie(
          'newAccount',
          window.btoa(JSON.stringify(accounts[foundedAccountIndex]))
        )
      }
      saveAllAccounts(accounts)

      if (trackEvent) track(trackEvent, { newUser: !resp.data.has_shared })
      getUserAsync(resp.data)

      if (!reload) {
        setCookie(
          'accountSwitichingNotification',
          `${messages.loggedInNotif} ${resp.data.email}`
        )
        if (redirectToAfterLogin && resp.data.has_shared) {
          window.location = redirectToAfterLogin
        } else if (
          resp.data &&
          !resp.data.has_shared &&
          window.location.pathname !== '/app/inbox'
        ) {
          await startUserPolling({ hasPassword })
        } else if (
          resp.data.has_shared &&
          !redirectToAfterLogin &&
          !window.location.pathname.startsWith('/app/')
        ) {
          window.location = '/app/inbox'
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        await fetchUser()
      } else {
        if (err.code === 'REAUTH') {
          const accounts = getAllAccounts()
          const reauthAccount = accounts.find(
            account => account[1] === getCookieForUserAuthentication()
          )
          if (reauthAccount) {
            getUserAsync({
              id: reauthAccount[0],
              email: reauthAccount[2],
              reauth: true
            })
          }
        } else {
          console.error('error: ', err)
        }
      }
    }
  }
  await fetchUser()
}

function showGmailAuthorizationWindow(search) {
  return !search.split('&')[2].includes('https://mail.google.com')
}

function askForAuthorization(
  locationSearch,
  email,
  suggestedAuthMethod = 'oauth',
  provider,
  isCustomAuth
) {
  if (!isCustomAuth && suggestedAuthMethod === 'oauth') {
    if (provider === 'yahoo') {
      window.location = loginMiddleware(
        'yahoo',
        locationSearch,
        email.trim().toLowerCase(),
        true
      )
    } else if (
      provider === 'google' &&
      showGmailAuthorizationWindow(locationSearch)
    ) {
      window.location = loginMiddleware(
        'google',
        locationSearch,
        email.trim().toLowerCase(),
        true
      )
    }
  } else if (isCustomAuth || suggestedAuthMethod === 'password') {
    const parsedString = parse(locationSearch)
    const parsedState = isCustomAuth
      ? parsedString
      : JSON.parse(window.atob(parsedString.state))

    const searchObj = {
      ...parsedState.query,
      email: email.trim().toLowerCase(),
      provider
    }
    window.location = `${
      window.location.origin
    }/a/appPassword/login?${stringify(searchObj)}`
  } else {
    throw new Error(`Auth method ${suggestedAuthMethod} not supported`)
  }
}

//{
//     "provider": "yahoo",
//     "secret": "25fvfjvcmh2aaujxjj647ckh6m67n826",
//     "method": "oauth",
//     "history": {
//         "length": 36,
//         "action": "POP",
//         "location": {
//             "pathname": "/oauth-result/yahoo",
//             "search": "?code=25fvfjvcmh2aaujxjj647ckh6m67n826&state=eyJyZWRpcmVjdF91cmkiOiJodHRwczovL2xvY2FsLnVucm9sbC5tZTozMDAwL2Evb2F1dGgtcmVzdWx0L3lhaG9vIiwicXVlcnkiOnsicmVkaXJlY3QiOiIiLCJlbWFpbCI6ImFya2FkMXlkb2xnb25vc0B5YWhvby5jb20iLCJpc0F1dGhvcml6YXRpb24iOnRydWV9fQ%3D%3D",
//             "hash": ""
//         }
//     },
//     "email": "arkad1ydolgonos@yahoo.com",
//     "redirectToAfterLogin": null
// }
export async function authorizeUserWithOauth(authParams) {
  try {
    const account = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/auth/authorize`,
      {
        ...authParams,
        isWeb: true
      }
    )

    setCookie('tempAuthToken', '')
    // authParams.dispatch(
    await getUser({
      token: account.data.token,
      trackEvent: 'user_has_shared',
      hasPassword: false,
      ...authParams
    })
    // )
    // authParams.dispatch(removeNotification())

    return account
  } catch (err) {
    eraseCookieForUserAuthentication()
    console.error('Oauth signup error', err)
    const errMsg = get(err, 'response.data.detail', 'Oauth Error')

    window.location = `${window.location.origin}/a/signup`
    // track(eventConstants.oauthResult.failed, {
    //   Page: eventConstants.oauthResult.pageName,
    //   [eventConstants.oauthResult.errMsgProp]: errMsg
    // })
    // authParams.dispatch(addNotification({ context: 'negative' }))
    throw new Error('AuthenticateUser', err)
  }
}

export async function authorizeUserWithAppPassword(authParams, history) {
  try {
    const account = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/auth/authorize`,
      {
        ...authParams,
        method: 'password',
        isWeb: true
      }
    )

    setCookie('tempAuthToken', '')

    await getUser({
      token: account.data.token,
      trackEvent: 'user_has_shared',
      hasPassword: true,
      isReauth: authParams.reauth,
      history
    })

    // dispatch(removeNotification())

    return account
  } catch (err) {
    eraseCookieForUserAuthentication()
    const errMsg = get(err, 'response.data.detail')
    const errDisplayMsg =
      errMsg === imapErrorDetail
        ? imapErrorMsg
        : formatMessage(messageDescriptors.authErrorNotif, {
            provider: providerData[authParams.provider].name
          })
    console.error('App Password login error:', errMsg)
    throw err
  }
}

async function createUser(
  accessToken,
  authParams,
  gdprParams,
  email,
  isCustomAuth
) {
  try {
    setCookie('tempAuthToken', accessToken)
    return await axios.post(`${process.env.REACT_APP_AUTH_API}/auth/signup`, {
      ...authParams,
      ...gdprParams,
      email: email.trim().toLowerCase(),
      isWeb: true
    })
  } catch (err) {
    console.error('Oauth signup error', err)

    if (isCustomAuth) {
      throw err
    } else {
      window.location = `${window.location.origin}/a/signup`
      throw new Error('AuthenticateUser', err)
    }
  }
}

async function loginUser(authParams, gdprParams, isCustomAuth) {
  try {
    const loginResp = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/auth/login`,
      {
        ...authParams,
        ...gdprParams,
        isWeb: true
      }
    )

    if (authParams.isMagicAuth && loginResp.data && loginResp.data.createUser) {
      window.location = `${window.location.origin}/signup`
      return
    }

    return loginResp
  } catch (err) {
    console.error('Login error', err)
    const errMsg = get(err, 'response.data.detail', 'Login Error')
    const errTitle = get(err, 'response.data.title', 'Login Error Title')

    if (isCustomAuth) {
      throw err
    } else {
      if (
        errTitle === 'IncorrectProviderError' &&
        errMsg === 'should be google'
      ) {
        window.location = `${window.location.origin}/signup?redirectProvider=google`
      } else {
        window.location = `${window.location.origin}/signup`
      }
      throw new Error('AuthenticateUser', err)
    }
  }
}

// For direct app-password signup/authorization
// Supported providers - Icloud
export async function authenticateUserForDirectAppPswdFlow(
  authParams,
  history
) {
  let gdprParams = getGdprParams()
  let createUserResp

  const loginResp = await loginUser(
    {
      ...authParams,
      method: 'password'
    },
    gdprParams,
    true
  ).catch(err => {
    const errMsg = get(err, 'loginResp.data.detail')
    const errDisplayMsg =
      errMsg === imapErrorDetail
        ? imapErrorMsg
        : formatMessage(messageDescriptors.authErrorNotif, {
            provider: providerData[authParams.provider].name
          })

    console.error('App Password authenticate error:', errMsg)
    throw err
  })

  // Create User
  if (loginResp && loginResp.data && loginResp.data.createUser) {
    setCookie('tempAuthToken', loginResp.data.token)

    createUserResp = await createUser(
      loginResp.data.token,
      { ...authParams, method: 'password' },
      gdprParams,
      loginResp.data.email.trim().toLowerCase()
    ).catch(() => {})
  }

  // IF new user created or user requires authorization, authorize
  if (
    get(createUserResp, 'data.authorize_method') ||
    get(loginResp, 'data.authorize_method')
  ) {
    setCookie(
      'tempAuthToken',
      createUserResp ? createUserResp.data.token : loginResp.data.token
    )
    await authorizeUserWithAppPassword(authParams, history)
  }
}

export async function authenticateUser(
  authParams,
  isCustomAuth,
  dispatch,
  searchParams
) {
  let gdprParams = getGdprParams()

  // await dispatch(getUser())
  // if user is already a member, then this sets user to new and then
  // when we getUser(), isUserNew is set to !has_shared value (which is
  // false if it's a returning user). This causes the welcome page to
  // show up for a second. If we want to fix this flicker, we need to
  // get user before setting cookie or calling userIsNew(). I played
  // with it but couldn't find a quick solution. Revisit at end if we
  // want to "fix"
  // Not exactly sure what this is, but if got it right, handing routing
  // inside redux, instead of react-router should solve this. Let's discuss when
  // we both have time.

  const loginResp = await loginUser(authParams, gdprParams, isCustomAuth)
  // Create User
  if (loginResp && loginResp.data && loginResp.data.createUser) {
    setCookie('tempAuthToken', loginResp.data.token)
    const createUserResp = await createUser(
      loginResp.data.token,
      authParams,
      gdprParams,
      loginResp.data.email.trim().toLowerCase()
    ).catch(() => {})

    setCookie('tempAuthToken', createUserResp.data.token)
    if (['google', 'yahoo'].includes(authParams.provider)) {
      return Promise.reject(
        askForAuthorization(
          // get(getState(), 'router.location.search'),
          searchParams,
          createUserResp.data.email.trim().toLowerCase(),
          createUserResp.data.authorize_method,
          authParams.provider,
          isCustomAuth
        )
      )
    }

    // Outlook, Aol
    return await authorizeUserWithOauth({
      ...authParams,
      email: loginResp.data.email.trim().toLowerCase()
    })
  }

  // Authorize user
  if (loginResp && loginResp.data && loginResp.data.authorize_method) {
    // Set token cookie so that authorize request can be authenticated
    // Using different cookie name than the default auth cookie, because if that cookie is set before user's full onboarding, the webapp
    // tries to start fetching user data and it's not there yet.
    // TODO: Use same auth token in future and fix related flows
    setCookie('tempAuthToken', loginResp.data.token)

    if (['google', 'yahoo'].includes(authParams.provider)) {
      return Promise.reject(
        askForAuthorization(
          // get(getState(), 'router.location.search'),
          searchParams,
          loginResp.data.email?.trim().toLowerCase(),
          loginResp.data.authorize_method,
          authParams.provider,
          isCustomAuth
        )
      )
    }

    // Outlook, Aol
    return await authorizeUserWithOauth({
      ...authParams,
      email: loginResp.data.email?.trim().toLowerCase()
    })
  }

  // User logged in. Fetch user details.
  setCookie('tempAuthToken', '')
  return await getUser({
    token: loginResp.data.token,
    trackEvent: 'user_has_shared',
    hasPassword: false,
    ...authParams
  })
}

// try 3 times to rescan user after 3 seconds
// if rescan fails more than 3 times, throw error

function updateUserAsync(partialUser) {
  return {
    type: UPDATE_USER,
    payload: {
      partialUser
    }
  }
}

// May be discuss on how to handle all the global app actions.
export async function updateUser(params, notification) {
  try {
    await axios.patch(`${process.env.REACT_APP_API}/users/me`, params)
    updateUserAsync(params)
    if (notification) {
      addNotification(notification)
    }
  } catch (err) {
    addNotification({ context: 'negative' })
  }
}

export function deleteUserAsync() {
  return {
    type: DELETE_USER
  }
}

export async function deleteUser() {
  await axios.delete(`${process.env.REACT_APP_API}/users/me`)
}

export async function reauthUnsubscribe(token) {
  try {
    await axios.post(`${process.env.REACT_APP_API}/unsubscribes`, null, {
      Authorization: `Bearer ${token}`
    })
  } catch (err) {
    console.log('error: ', err)
  }
}

export async function approveDataRequest(token) {
  try {
    await axios.post(`${process.env.REACT_APP_API}/archives/approve`, null, {
      Authorization: `Bearer ${token}`
    })
  } catch (err) {
    console.log('error: ', err)
  }
}

export function submitEmailForLogin(email) {
  return axios.post(`${process.env.REACT_APP_API}/users/notify`, {
    email: email?.trim().toLowerCase(),
    subject: 'authenticate-account'
  })
}

export async function getAuthenticateActionForCustomAuth(
  email,
  provider,
  reauth
) {
  const paramsObj = {
    email: email?.trim().toLowerCase(),
    provider
  }

  if (reauth) {
    paramsObj.reauth = true
  }
  const resp = await axios.get(
    `${process.env.REACT_APP_AUTH_API}/auth/action?${stringify(paramsObj)}`
  )

  return resp.data.action
}

export async function startAppClipSignupPolling(email, provider, search) {
  const notifyIfSignedUp = async () => {
    try {
      const parsedSearch = parse(search)
      const customAction = await getAuthenticateActionForCustomAuth(
        email?.trim().toLowerCase(),
        provider,
        !!parsedSearch.reauth
      )

      if (/appPassword\/login/.test(window.location.pathname)) {
        return
      }

      if (customAction !== 'login') {
        setTimeout(notifyIfSignedUp, 1000)
        return
      }

      await submitEmailForLogin(email?.trim().toLowerCase())

      // Redirect to validate modal
      window.location = `/authenticate/validate${search}`
    } catch (err) {
      console.error('Notify polling failure', err)
      throw err
    }
  }
  await notifyIfSignedUp()
}

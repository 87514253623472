import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ReactComponent as SignupGoogleLogo } from '../../../global/signUpLogos/SignupGoogleLogo.svg'
import { ReactComponent as SignupYahooLogo } from '../../../global/signUpLogos/SignupYahooLogo.svg'
import { ReactComponent as SignupOutlookLogo } from '../../../global/signUpLogos/SignupOutlookLogo.svg'
import { ReactComponent as SignupAolLogo } from '../../../global/signUpLogos/SignupAolLogo.svg'
import { ReactComponent as SignupIcloudLogo } from '../../../global/signUpLogos/SignupIcloudLogo2.svg'
import { SignupMailbox } from '../SignupMailbox'
import { useAuth } from '../../../hook/useAuth'
import AuthAccountModal from '../AuthAccountModal'

const logos = [
  ['google', <SignupGoogleLogo />],
  ['yahoo', <SignupYahooLogo />],
  ['outlook', <SignupOutlookLogo />],
  ['icloud', <SignupIcloudLogo />],
  ['aol', <SignupAolLogo />]
]

const Wrapper = styled.div`
  display: flex;
  line-height: 100%;
  margin: 65px 80px;
`

const Main = styled.div`
  display: block;
  width: 50%;
  line-height: 100%;
`
const ImageContainer = styled.div`
  display: block;
  width: 50%;

  > img {
    display: block;
    margin: auto;
    height: 400px;

    @media (min-width: 1400px) {
      height: 600px;
    }

    @media (min-width: 1800px) {
      height: 800px;
    }

    @media (min-width: 2300px) {
      height: 1000px;
    }
  }
`

const SignUpWrapper = styled.div`
  margin: auto;
  margin-top: 20px;
  width: 450px;
  border-radius: 50px;
  border: solid 2px rgba(0, 0, 0, 0.1);
`

const Title = styled.div`
  font-size: 60px;
  margin-bottom: 22px;
  font-weight: bold;
  line-height: 100%;
  color: #000;

  @media (min-width: 1400px) {
    font-size: 80px;
  }

  @media (min-width: 1800px) {
    font-size: 100px;
  }

  @media (min-width: 2300px) {
    font-size: 120px;
  }
`

const SubTitle = styled.div`
  font-size: 30px;
  color: #404040;
  line-height: 1.4;
  margin-bottom: 40px;

  @media (min-width: 1400px) {
    font-size: 40px;
  }

  @media (min-width: 1800px) {
    font-size: 50px;
  }

  @media (min-width: 2300px) {
    font-size: 70px;
  }
`

const SelectText = styled.div`
  font-size: 22px;
  color: #404040;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.4;

  @media (min-width: 1400px) {
    font-size: 30px;
  }

  @media (min-width: 1800px) {
    font-size: 40px;
  }

  @media (min-width: 2300px) {
    font-size: 55px;
  }
`

const SelectMailbox = styled.div`
  display: flex;
  gap: 40px;
`

const MailboxWrapper = styled.div`
  cursor: pointer;

  & > svg {
    width: 40px;
    height: 40px;

    @media (min-width: 1400px) {
      width: 55px;
      height: 55px;
    }

    @media (min-width: 1800px) {
      width: 65px;
      height: 65px;
    }

    @media (min-width: 2300px) {
      width: 80px;
      height: 80px;
    }
  }
`

const LinkWrapper = styled.a`
  color: #00bcbb;
  text-decoration: none;

  &:hover {
    color: #00cccb;
    text-decoration: none;
  }
`

const PrivacyWrapper = styled.div`
  width: 370px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
  line-height: 110%;
  color: #404040;

  @media (min-width: 1400px) {
    font-size: 22px;
  }

  @media (min-width: 1800px) {
    font-size: 27px;
  }

  @media (min-width: 2300px) {
    font-size: 35px;
  }
`

const LoginContainer = () => {
  const { showAddMailboxWindow, getEmailProvider } = useAuth()
  const dispatch = useDispatch()
  const [selectedProvider, setSelectedProvider] = useState()
  const [selectedEmail, setSelectedEmail] = useState()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const email = searchParams.get('selected')
    if (email) {
      getEmailProvider(email).then(provider => {
        if (provider) {
          setSelectedProvider(provider)
          setSelectedEmail(email)
          setShowAuthModal(true)
        }
      })
    }
    searchParams.delete('selected')
    setSearchParams(searchParams, { replace: true })
  }, [])

  const onSelectMailbox = type => {
    if (['icloud', 'google'].includes(type)) {
      setSelectedProvider(type)
      // dispatch(updateAddMailboxModal({ show: true, type: type }))
    } else {
      setSelectedProvider(undefined)
      showAddMailboxWindow(type)
    }
  }
  return (
    <Wrapper>
      <Main>
        <Title>
          {' '}
          <span style={{ color: '#00bcbb' }}>Connect</span> your inbox below.
        </Title>
        <SubTitle>
          Connect your email today and say goodbye to those annoying emails.
        </SubTitle>
        <SelectText>Select your email provider to get started:</SelectText>
        <SelectMailbox>
          {logos.map(logo => (
            <MailboxWrapper
              data-id={logo[0]}
              onClick={() => {
                onSelectMailbox(logo[0])
              }}>
              {logo[1]}
            </MailboxWrapper>
          ))}
        </SelectMailbox>
        <PrivacyWrapper style={{ marginTop: '20px' }}>
          By connecting your mailbox with Unroll.Me, you understand that you may
          be enrolled in our{' '}
          <LinkWrapper target="_blank" href="/your-data">
            measurement panel
          </LinkWrapper>
          , and you are agreeing to our{' '}
          <LinkWrapper target="_blank" href="/legal/terms">
            Terms of Service
          </LinkWrapper>{' '}
          and{' '}
          <LinkWrapper target="_blank" href="/legal/privacy">
            Privacy Policy
          </LinkWrapper>
          .
        </PrivacyWrapper>
      </Main>
      {['icloud', 'google'].includes(selectedProvider) ? (
        <SignUpWrapper>
          <SignupMailbox provider={selectedProvider} mail={selectedEmail} />
        </SignUpWrapper>
      ) : (
        <ImageContainer>
          <img
            src={`${process.env.PUBLIC_URL}/images/iphone-12.png`}
            alt="iphone"
          />
        </ImageContainer>
      )}

      {['yahoo', 'outlook', 'aol'].includes(selectedProvider) &&
        showAuthModal && (
          <AuthAccountModal
            show={showAuthModal}
            setShow={setShowAuthModal}
            email={selectedEmail}
            provider={selectedProvider}
          />
        )}
    </Wrapper>
  )
}

export default LoginContainer

import React from 'react'
import { Button, ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { KeepLabel, RollupLabel, UnsubscribeLabel } from './styled'
import Unsubscribe from '../../../containers/Unsubscribe'

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .progress {
    width: 620px;
    height: 34px;
    border-radius: 17px;
    border: solid 1px rgba(60, 194, 200, 0.75);
    background-color: #fff;
  }

  .progress-bar {
    background-color: rgba(60, 194, 200, 0.75) !important;
    border-radius: 17px;
  }

  .progress-content {
    position: relative;
    margin-top: 44px;
  }

  .progress-content-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: #00bcbb;
  }

  img {
    width: 450px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`
const Tutorial2 = ({ nextStep }) => {
  return (
    <>
      <Styled>
        <h3>
          {' '}
          <UnsubscribeLabel>Unsubscribe</UnsubscribeLabel>
        </h3>
        <p>Easily unsubscribe with one click.</p>
        <p>We'll remove any unwanted email subscriptions from your inbox.</p>
        <img
          width="450px"
          src={`${process.env.PUBLIC_URL}/images/tutorial/tutorial2.png`}
          alt="tutorial"
        />
      </Styled>
      <Button
        onClick={() => {
          nextStep()
        }}>
        Awesome!
      </Button>
    </>
  )
}

export default Tutorial2
